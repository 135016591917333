<script>
import "@vueform/multiselect/themes/default.css";
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config.json";
import Swal from "sweetalert2";
import animationData from "@/components/widgets/msoeawqm.json";
import Multiselect from "@vueform/multiselect";

export default {
    page: {
        title: "Transmitted Results",
        meta: [{
            name: "description",
            content: appConfig.description,
        }, ],
    },
    data() {
        return {
            title: "Transmitted Results",
            items: [{
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "Transmitted Results",
                    active: true,
                },
            ],
            value: null,
            userTransmittedResults: [],
            defaultOptions: {
                animationData: animationData,
            },

            //Results Form
            formData: {
                prezndcvotes: "",
                preznppvotes: "",
              palndcvotes: "",
              palnppvotes: "",
                pinksheet: null,
             pinksheet_pal: null,
                station_id: "",
              prezpal:null,
            },

            //Modal
            modalTitle: "",
            fileList: {},

            //user stations
            userStations: [],
        };
    },
    components: {
        Layout,
        PageHeader,

        Multiselect
    },
    methods: {

        handleAvatar() {
            this.formData.pinksheet = this.$refs.file.files[0];
        },

      handlePalPinkSheet() {
        this.formData.pinksheet_pal = this.$refs.palpinksheet.files[0];
      },



        getUserTransmittedResults() {
            this.$store
                .dispatch("profile/getUserTransmittedResults", this.$route.params.id)
                .then((res) => {

                    this.userTransmittedResults = res.data;
                })
                .catch((err) => console.log(err));
        },

        //open edit User
        openEditClient(data,isPrezOrPal) {
if( isPrezOrPal ==="prez"){
  this.modalTitle = "Edit President Results";
} else {
  this.modalTitle = "Edit Parliament Results";
}

            this.formData.prezpal = isPrezOrPal
            this.formData = {
                ...data,
            };

        },


      openEditPalModal(data,isPrezOrPal) {

          this.modalTitle = "Edit Parliament Results";


        this.formData.prezpal = isPrezOrPal
        this.formData = {
          ...data,
        };

      },

        //add and edit User data
        openUserModal() {
            Object.keys(this.formData).forEach(key => this.formData[key] = "")
            this.modalTitle = 'Add Presidential Results'
        },


      openPalModal() {
        Object.keys(this.formData).forEach(key => this.formData[key] = "")
        this.modalTitle = 'Add Parliamentary Results'
      },


        addPresidentResult() {
            let userFormData = new FormData()
            userFormData.append('prezndcvotes', this.formData.prezndcvotes)
            userFormData.append('preznppvotes', this.formData.preznppvotes)
            userFormData.append('station_id', this.formData.station_id)

            if (typeof this.formData.pinksheet !== 'undefined') {
                userFormData.append('pinksheet', this.formData.pinksheet)
            }
          console.log(this.formData.pinksheet)
            if (this.modalTitle === "Add Presidential Results") {

                this.fileList = {}
                Object.keys(this.formData).forEach(key => this.formData[key] = "")
                this.$store
                    .dispatch("profile/addPresidentResult", userFormData)
                    .then(() => {
                        this.getUserTransmittedResults();
                        Swal.fire("Success!", "Data Added successfully.", "success");
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {

                userFormData.append("_method", "post")
                this.$store
                    .dispatch("profile/editPresidentResult", {
                        id: this.formData.id,
                        data: userFormData
                    })
                    .then(() => {
                        this.getUserTransmittedResults();
                        Swal.fire(
                            "Success!",
                            "Data updated successfully.",
                            "success"
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            document.getElementById("closemodal").click();
            document.getElementById("addform").reset();
        },

      addParliamentResult() {
        let userFormData = new FormData()
        userFormData.append('palndcvotes', this.formData.palndcvotes)
        userFormData.append('palnppvotes', this.formData.palnppvotes)
        userFormData.append('station_id', this.formData.station_id)
console.log(this.formData.palpinksheet)

        if (typeof this.formData.pinksheet_pal !== 'undefined') {
          userFormData.append('pinksheet', this.formData.pinksheet_pal)
        }

        if (this.modalTitle === "Add Parliamentary Results") {

          this.fileList = {}
          Object.keys(this.formData).forEach(key => this.formData[key] = "")
          this.$store
              .dispatch("profile/addParliamentResult", userFormData)
              .then(() => {
                this.getUserTransmittedResults();
                Swal.fire("Success!", "Data Added successfully.", "success");
              })
              .catch((err) => {
                console.log(err);
              });
        } else {
console.log(this.formData)
          userFormData.append("_method", "post")
          this.$store
              .dispatch("profile/editParliamentResult", {
                id: this.formData.id,
                data: userFormData
              })
              .then(() => {
                this.getUserTransmittedResults();
                Swal.fire(
                    "Success!",
                    "Data updated successfully.",
                    "success"
                );
              })
              .catch((err) => {
                console.log(err);
              });
        }
        document.getElementById("closePalModal").click();
        document.getElementById("addPalForm").reset();
      },
    },
    mounted() {
        const userId = localStorage.getItem("userid")

        this.getUserTransmittedResults();

        this.$store.dispatch("profile/getUserAssignedStations", userId)
            .then(res => this.userStations = res.data.map(role => {

                return {
                    label: role.station.stationcode + " " + role.station.stationname,
                    value: role.id
                }

            }))
    },

  computed: {

    avatarUrl() {
      return `${process.env.VUE_APP_URL}/uploads/`;
    }
  },

};
</script>

<template>
<Layout>
    <PageHeader :title="title" :items="items" />

  <!-- Results Details -->
    <div class="row">
        <div class="col-12">
            <div class="card">
              <div class="card-header d-flex align-items-center border-0">
                <h5 class="card-title mb-0 flex-grow-1">Transmitted Results</h5>
                <div class="flex-shrink-0">
                  <div class="flax-shrink-0 hstack gap-2">
                    <button class="btn btn-primary" type="button" @click="openUserModal"  data-bs-toggle="modal" id="create-btn" data-bs-target="#showPrezResultModal"><i class="ri-add-line align-bottom me-1"></i>Add Presidential Results</button>
                    <button class="btn btn-secondary" type="button" @click="openPalModal"  data-bs-toggle="modal" id="create-btn" data-bs-target="#showPalResultModal"><i class="ri-add-line align-bottom me-1"></i>Add Parliamentary Results</button>
                  </div>
                </div>
              </div>

                <div class="card-body">
                    <div class="tab-content">
                        <div class="tab-pane active" id="profile-1" role="tabpanel">

                            <div class="table-responsive table-card">
                                <table class="table align-middle mb-0 table-bordered">

                                    <thead class="table-dark">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col" colspan="2" class="align-middle">Station</th>

                                            <th scope="col" colspan="4">President</th>

                                            <th scope="col" colspan="4">Parliament</th>

                                        </tr>
                                    </thead>
                                    <thead class="table-light text-muted">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Station Code</th>
                                            <th scope="col">Station Name</th>
                                            <th scope="col">NDC</th>
                                            <th scope="col">NPP</th>
                                            <th scope="col">Pink Sheet</th>
                                            <th scope="col">Action</th>

                                            <th scope="col">NDC</th>
                                            <th scope="col">NPP</th>
                                            <th scope="col">Pink Sheet</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(data, index) in userTransmittedResults" :key="data.id">
                                            <th scope="row">
                                                {{ index + 1 }}
                                            </th>
                                            <td>{{ data.station.stationcode }}</td>
                                            <td>{{ data.station.stationname }}</td>

                                            <td>{{ data.prezndcvotes }}</td>
                                            <td>{{ data.preznppvotes }}</td>
                                            <td>
<template v-if="data.prezpinksheet">

  <div class="d-flex align-items-start text-muted">
    <div class="flex-shrink-0 me-3">
      <img  :src="avatarUrl+`prezpinksheets/${data.prezpinksheet }`" class="avatar-sm rounded" alt="...">
    </div>
    <div class="flex-grow-1">
      <h6 class="fs-14">

        <a :href="avatarUrl+`prezpinksheets/${data.prezpinksheet }`" target="_blank">       View Pinksheet</a>



      </h6>

    </div>
  </div>

</template>
                                            </td>
                                            <td>

                                                <button @click="openEditClient(data,isPrezOrPal= 'prez')" class="dropdown-item" data-bs-toggle="modal" id="edit-btn"
                                                        data-bs-target="#showPrezResultModal">
                                                    <i class="ri-pencil-fill align-bottom me-2 text-muted"></i>
                                                    Update Results</button>

                                            </td>

                                            <td>{{ data.palndcvotes }}</td>
                                            <td>{{ data.palnppvotes }}</td>
                                            <td>


                                              <template v-if="data.palpinksheet">

                                                <div class="d-flex align-items-start text-muted">
                                                  <div class="flex-shrink-0 me-3">
                                                    <img  :src="avatarUrl+`palpinksheets/${data.palpinksheet }`" class="avatar-sm rounded" alt="...">
                                                  </div>
                                                  <div class="flex-grow-1">
                                                    <h6 class="fs-14">

                                                      <a :href="avatarUrl+`palpinksheets/${data.palpinksheet }`" target="_blank">       View Pinksheet</a>



                                                    </h6>

                                                  </div>
                                                </div>

                                              </template>



                                            </td>
                                            <td>


                                              <button @click="openEditPalModal(data,{isPrezOrPal: 'pal'})" class="dropdown-item" data-bs-toggle="modal" id="edit-btn"
                                                      data-bs-target="#showPalResultModal">
                                                <i class="ri-pencil-fill align-bottom me-2 text-muted"></i>
                                                Update Results</button>

                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                                <!--end table-->
                            </div>
                        </div>
                        <!--edn tab-pane-->
                    </div>
                    <!--end tab-content-->
                </div>
            </div>
        </div>
    </div>
    <!--end row-->

    <!-- Add/Edit Prez Modal -->
    <div class="modal fade" id="showPrezResultModal" tabindex="-1" aria-labelledby="showPrezResultModal" aria-hidden="true">

        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"></button>
                </div>
                <form action="#" id="addform" @submit.prevent="addPresidentResult">
                    <div class="modal-body">

                        <div class="mb-3"  v-if="modalTitle === 'Add Presidential Results'">
                            <label class="form-label" for="role">Station</label>
                            <Multiselect required class="form-control" id="role" v-model="formData.station_id" :close-on-select="true" :searchable="true" :create-option="true" :options="userStations" />
                        </div>

                        <div class="mb-3" id="modal-id">
                            <label for="name" class="form-label">President - NDC</label>
                            <input type="text" v-model="formData.prezndcvotes" id="prezndcvotes" class="form-control" placeholder="Enter NDC Results" required />

                        </div>

                        <div class="mb-3">
                            <label for="preznppvotes" class="form-label">President - NPP</label>
                            <input type="text" v-model="formData.preznppvotes" id="preznppvotes" class="form-control" placeholder="Enter NPP Results" required />
                        </div>

                        <div class="mb-3">
                            <label for="pinksheet" class="form-label">Pink Sheet</label>
                            <input type="file" class="form-control" id="file" ref="file" @change="handleAvatar" />
                        </div>

                    </div>
                    <div class="modal-footer">
                        <div class="hstack gap-2 justify-content-end">
                            <button type="button" class="btn btn-light" data-bs-dismiss="modal" id="closemodal">
                                Close
                            </button>
                            <button type="submit" class="btn btn-success" id="add-btn">
                                {{ modalTitle }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
  <!-- Add/Edit Prez Modal -->



  <!-- Add/Edit Pal Modal -->
  <div class="modal fade" id="showPalResultModal" tabindex="-1" aria-labelledby="showPalResultModal" aria-hidden="true">

    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-light p-3">
          <h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"></button>
        </div>
        <form action="#" id="addPalForm" @submit.prevent="addParliamentResult">
          <div class="modal-body">

            <div class="mb-3"  v-if="modalTitle === 'Add Parliamentary Results'">
              <label class="form-label" for="role">Station</label>
              <Multiselect required class="form-control" id="role" v-model="formData.station_id" :close-on-select="true" :searchable="true" :create-option="true" :options="userStations" />
            </div>

            <div class="mb-3" id="modal-id">
              <label for="name" class="form-label">Parliament - NDC</label>
              <input type="text" v-model="formData.palndcvotes" id="palndcvotes" class="form-control" placeholder="Enter NDC Results" required />

            </div>

            <div class="mb-3">
              <label for="palnppvotes" class="form-label">Parliament - NPP</label>
              <input type="text" v-model="formData.palnppvotes" id="palnppvotes" class="form-control" placeholder="Enter NPP Results" required />
            </div>

            <div class="mb-3">
              <label for="pinksheet" class="form-label">Pink Sheet</label>
              <input type="file" class="form-control" id="palpinksheet" ref="palpinksheet" @change="handlePalPinkSheet" />
            </div>

          </div>
          <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal" id="closePalModal">
                Close
              </button>
              <button type="submit" class="btn btn-success" id="add-btn">
                {{ modalTitle }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Add/Edit Pal Modal -->

  <!-- Show Results Model -->
    <div class="modal fade" id="showClientDetailModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">User Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive">
                        <!--              <table class="table table-borderless mb-0">-->
                        <!--                <tbody>-->
                        <!--                <tr>-->
                        <!--                  <th class="ps-0" scope="row">User :</th>-->
                        <!--                  <td class="text-muted">{{ UserDetailsData.name}}</td>-->
                        <!--                </tr>-->
                        <!--                <tr>-->
                        <!--                  <th class="ps-0" scope="row">Email :</th>-->
                        <!--                  <td class="text-muted">{{ UserDetailsData.email }}</td>-->
                        <!--                </tr>-->
                        <!--                <tr>-->
                        <!--                  <th class="ps-0" scope="row">Phone :</th>-->
                        <!--                  <td class="text-muted">{{UserDetailsData.phone}}</td>-->
                        <!--                </tr>-->
                        <!--                <tr>-->
                        <!--                  <th class="ps-0" scope="row">Role :</th>-->
                        <!--                  <td class="text-muted">-->
                        <!--                    {{ UserDetailsData.role && UserDetailsData.role.name }}-->
                        <!--                  </td>-->
                        <!--                </tr>-->
                        <!--                <tr>-->
                        <!--                  <th class="ps-0" scope="row">Status:</th>-->
                        <!--                  <td class="text-muted">-->
                        <!--                      <span class="badge text-uppercase" :class="[-->
                        <!--                            UserDetailsData.status_id === 1 ? 'badge-soft-success': 'badge-soft-danger',-->
                        <!--                        ]">{{ UserDetailsData.status_id === 1 ? 'Active' : 'Inactive' }}</span>-->
                        <!--                  </td>-->
                        <!--                </tr>-->
                        <!--                </tbody>-->
                        <!--              </table>-->
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="hstack gap-2 justify-content-end">
                        <button type="button" class="btn btn-light" data-bs-dismiss="modal" id="closemodal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <!-- Show Results Model -->
</Layout>
</template>
