<script>
import appConfig from "../../app.config.json";

import results from "@/views/profile/Results";

import AdminDashboard from "@/views/dashboard/AdminDashboard";
export default {
  page: {
    title: "Starter",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Starter",
      items: [
        {
          text: "Pages",
          href: "/",
        },
        {
          text: "Starter",
          active: true,
        },
      ],

      //isAdmin
      isAdmin:false
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components

    // eslint-disable-next-line vue/no-unused-components
    results,

    AdminDashboard
  },
  mounted() {

  }
  ,
  created() {
    console.log(localStorage.getItem("roleid"))
    var roleid = localStorage.getItem("roleid")
    if(roleid === "6") {
      this.isAdmin = true
    }

    console.log(this.isAdmin)
  }
};
</script>

<template>
  <div>
    <AdminDashboard v-if="isAdmin"/>
    <results v-else/>
  </div>
</template>
